<template>
  <div>
    <h3>INFORMACION A USUARIOS FINANCIEROS</h3>
    <h4>Tasas, comisiones y cargos</h4>
    <div style="overflow-x: auto">
      <table>
        <tr>
          <th>Producto</th>
          <th>Plazo</th>
          <th></th>
          <th>Tasa Nominal<br />Anual (TNA)</th>
          <th>Tasa<br />Efectiva<br />Anual (TEA)</th>
          <th>CFTNA con<br />IVA</th>
          <th>CFTEA con<br />IVA</th>
          <th>Comisiones</th>
          <th>Precancelacion<br />anticipada</th>
          <th>Seguros</th>
        </tr>
        <tr>
          <td>Prestamos personales</td>
          <td>{{rates.minPeriod}} a {{ rates.maxPeriod }} meses</td>
          <td>Minima<br />Maxima</td>
          <td>
            {{ rates.minTNAWithoutIva }}%<br />{{
              rates.maxTNAWithoutIva
            }}%
          </td>
          <td>
            {{ rates.minTEA }}%<br />{{
              rates.maxTEA
            }}%
          </td>
          <td>
            {{ rates.minCFTNAWithIva }}%<br />{{
              rates.maxCFTNAWithIva
            }}%
          </td>
          <td>
            {{ rates.minCFTEAWithIva }}%<br />{{
              rates.maxCFTEAWithIva
            }}%
          </td>
          <td>0%<br />0%</td>
          <td>0%<br />0%</td>
          <td>0%<br />0%</td>
        </tr>
      </table>
    </div>
    <h5>Desliza hacia la derecha para ver la tabla completa.</h5>
  </div>
</template>

<script>
export default {
  name: "PersonalAllons",
  data: function () {
    return {
      rates: {
        minTNAWithoutIva: '',
        maxTNAWithoutIva: '',
        minTEA: '',
        maxTEA: '',
        minCFTNAWithIva: '',
        maxCFTNAWithIva: '',
        minCFTEAWithIva: '',
        maxCFTEAWithIva: '',
        exampleRequestedAmount: '',
        examplePaymentPeriodAmount: '',
        exampleTotalAmountToPay: '',
        exampleInstallmentAmount: '',
        exampleTNAWithoutIVA: '',
        exampleCFTNAWithIVA: '',
        exampleCFTEAWithIVA: '',
        exampleTEAWithoutIVA: '',
        lastUpdate: '',
        minPeriod: '',
        maxPeriod: '',
        punitiveInterest:''
      },
    }
  },
  created() {
    this.$apiserver.getGeneralRates()
        .then((data) => {
          let rates = data.data;
          this.rates.minTNAWithoutIva = rates.minTNAWithoutIva
          this.rates.maxTNAWithoutIva = rates.maxTNAWithoutIva
          this.rates.minTEA = rates.minTEA
          this.rates.maxTEA = rates.maxTEA
          this.rates.minCFTNAWithIva = rates.minCFTNAWithIva
          this.rates.maxCFTNAWithIva = rates.maxCFTNAWithIva
          this.rates.minCFTEAWithIva = rates.minCFTEAWithIva
          this.rates.maxCFTEAWithIva = rates.maxCFTEAWithIva
          this.rates.exampleRequestedAmount = rates.exampleRequestedAmount
          this.rates.examplePaymentPeriodAmount = rates.examplePaymentPeriodAmount
          this.rates.exampleTotalAmountToPay = rates.exampleTotalAmountToPay
          this.rates.exampleInstallmentAmount = rates.exampleInstallmentAmount
          this.rates.exampleTNAWithoutIVA = rates.exampleTNAWithoutIVA
          this.rates.exampleCFTNAWithIVA = rates.exampleCFTNAWithIVA
          this.rates.exampleCFTEAWithIVA = rates.exampleCFTEAWithIVA
          this.rates.exampleTEAWithoutIVA = rates.exampleTEAWithoutIVA
          this.rates.lastUpdate = rates.tycLastUpdate
          this.rates.minPeriod = rates.minPaymentPeriodAmount;
          this.rates.maxPeriod = rates.maxPaymentPeriodAmount;
          this.rates.punitiveInterest = rates.punitiveInterestAmount;
        })
        .catch((e) => {
          console.log(e.message);
        })
  },
};
</script>


<style scoped>
.textAlignLeft {
  text-align: left;
  margin-left: 100px;
}
table {
  width: 100%;
}
th {
  text-align: center;
  padding: 0px 10px;
}
td {
  text-align: left;
  padding: 0px 10px;
}
</style>
